import React from 'react'
import { Link } from 'react-router-dom'


const Footer = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const Reload = () => {
        window.location.Reload()
    }
    var language = localStorage.getItem("language")

    return (
        <footer className="wpo-site-footer">
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} className="logo" to="/">Orhaset 2022</Link>
                                </div>
                                {language === 'fi-fi' &&
                                    <p>”Jos pidät minusta, lupaat poimia minulle kaikki tuikkivat tähdet tummalta taivaankannelta. Jos pidät minusta oikein paljon, lupaat noutaa minulle hopeisen kuun taivaalta. Mutta jos rakastat minua, et tee turhia lupauksia.” – Nyyti</p>
                                }
                                {language === 'en-us' && <p>“If you like me, you’ll promise to capture every shining star from the dark sky for me. If you really like me, you’ll promise to capture the silver moon for me. But if you truly love me, you won’t make empty promises” – Nyyti</p>}
                            </div>
                        </div>
                        <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    {language === 'fi-fi' &&
                                        <h3>Minne mennään?</h3>
                                    }
                                    {language === 'en-us' &&
                                        <h3>Where to?</h3>}
                                </div>
                                {language === 'fi-fi' &&
                                    <ul>
                                        <li><Link onClick={[ClickHandler, Reload]} to="/home">Etusivu</Link></li>
                                        <li><Link onClick={ClickHandler} to="/info">Lisätietoa</Link></li>
                                        <li><Link onClick={ClickHandler} to="/groom-bride">Tukijoukot</Link></li>
                                    </ul>
                                }
                                {language === 'en-us' &&
                                    <ul>
                                        <li><Link onClick={[ClickHandler, Reload]} to="/home">Home</Link></li>
                                        <li><Link onClick={ClickHandler} to="/info">Info</Link></li>
                                        <li><Link onClick={ClickHandler} to="/groom-bride">Best People</Link></li>
                                    </ul>
                                }
                            </div>
                        </div>
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    {language === 'fi-fi' &&
                                    <h3>Yhteystiedot </h3>
                            }
                            {language === 'en-us' &&
                                    <h3>Contact information </h3>
                            }
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <h5>Ville</h5>
                                        <li><i className="fi flaticon-email"></i>vorhanen@gmail.com</li>
                                        <li><i className="fi flaticon-phone-call"></i>+358 40 562 7175</li>
                                        <h5>Sara</h5>
                                        <li><i className="fi flaticon-email"></i>sarahekkala@yahoo.com</li>
                                        <li><i className="fi flaticon-phone-call"></i>+358 40 416 6638</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;