import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PortfolioSection from '../../components/PortfolioSection';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import StorySection from '../../components/StorySection';
import CoupleSection from '../../components/CoupleSection';
import LoginPage from '../LoginPage';

const StoryPage =() => {
    if(localStorage.getItem("userName")!== null){
        return (
        <Fragment>
            <Navbar2/>
            <CoupleSection/>
            <StorySection/>
            <PortfolioSection pSclass={'pt-0'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
}
else {
    return(
        <LoginPage/>
    )
}
};
export default StoryPage;
