import React, { useState } from 'react';
import SectionTitle from '../SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import LocationImageFI from '../../images/event/Location.fin.jpg';
import LocationImageEN from '../../images/event/Location.eng.jpg';


import Shape from '../../images/info/shape.jpg'


const InfoSection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    const Tabs = [
        {
            Id: '1',
            tabItem: 'Aikataulu?',
        },
        {
            Id: '2',
            tabItem: 'Majoitus?',
        },
        {
            Id: '3',
            tabItem: 'Autot?',
        },
        {
            Id: '4',
            tabItem: 'Pukukoodi?',
        },
        {
            Id: '5',
            tabItem: 'Korona?',
        }
    ]

    const Info = [
        {
            Id: '1',
            Shape: Shape,
            sTitle: 'Aikataulu',
            descriptions: [
                'Hääpäivämme on lauantai 24.9.2022. Juhlapäivä alkaa vihkimisellä Pyhän Birgitan kirkossa klo 15.00 osoitteessa Kirkkopolku 1, 37500 Lempäälä.',
                'Juhlapäivä jatkuu vihkitilaisuuden jälkeen Tampereella ravintola Sorellassa osoitteessa Vuolteenkatu 20, 33100 Tampere. Kirkolta juhlapaikalle on noin 20 minuutin ajomatka. Toivoisimme, että vieraat saapuvat vihkitilaisuuden jälkeen ravintolaan noin klo 16.00, kuitenkin viimeistään 16.30.',
                'Halukkaille ilta jatkuu vielä hääjuhlan jälkeen Sorellan alakerrassa olevassa Cellar Clubissa livemusiikin ja tanssin kera klo 20.00 eteenpäin.',
                'Toivomme myös, että kynnelle kykenevät häävieraat liittyisivät seuraamme Lapland Hotels Arenan aamiaiselle sunnuntaina 25.9. klo 10.00. Mainitsethan aamiaisella, että olet Villen ja Saran hääseuruetta, jolloin sinut ohjataan oikeaan osoitteeseen.'
            ]
        },
        {
            Id: '2',
            Shape: Shape,
            sTitle: 'Majoitus',
            image: LocationImageFI,
            descriptions: [
                'Olemme saaneet Tampereen Lapland Hotels Arenalta ryhmäkoodin häiden majoittumista varten! Hotelli on aivan juhlapaikan vieressä, ja myös hääpari viettää hääyönsä siellä.',
                'Vieraat varaavat huoneensa itse suoraan hotellista tunnuksella Häät Ville & Sara. Varaustunnusta voi käyttää vain puhelimitse varattaessa – soita siis hotellille numeroon +358 40 355 9068, ja mainitse, että olet varaamassa huonetta koodilla Häät Ville & Sara. Tarjoushintaiset huoneet ovat varattavissa 23.–25.9.2022 väliselle ajalle ja tarjoushintainen huone täytyy olla varattuna 25.8.2022 mennessä. Tämän jälkeen varaamattomat huoneet vapautetaan hotellin yleiseen myyntiin, ja niitä voi tiedustella saatavuuden mukaan päivän hinnalla.',
                'Huonevaihtoehdot:',
                '135 € / room for one / night / Misty Comfort -room',
                '155 € / room for two / night / Misty Comfort -room',
                'tai',
                '165 € / room for one / night / Comfort Loft –huone',
                '185 € / room for two / night / Comfort Loft –huone',
                '215 € / kolmen tai neljän hengen huone / night / Comfort Loft -room',
                'tai',
                '185 € / room for one / night / Mystique DeLuxe -room',
                '205 € / room for two /night / Mystique DeLuxe -room',
                'tai',
                '245 € / room for two / night / DeLuxe Spa -room',
                'Huoneet sisältävät buffetaamiaisen ja hotellin asiakassaunojen käytön.',
                'Hotellin yhteystiedot: arena@laplandhotels.com tai +358 40 355 9068.',
                'Huoneita on vieraille rajattu määrä.'
            ]
        },
        {
            Id: '3',
            Shape: Shape,
            sTitle: 'Autot',
            descriptions: [
                'Lempäälän kirkon edessä on parkkipaikka. Jos paikat ovat täynnä, lisää paikkoja löytyy tien toisella puolella olevan Tokmannin edestä tai kirkon viereisen rakennuksen takana olevien liikkeiden edestä (Tampereentie 19, 37500 Lempäälä).',
                'Tampereen keskustan pysäköintimahdollisuudet juhlapaikan ja hotellin läheisyydestä:',
                '•  Aimo Park Arena',
                '•  Finnpark Tullintori',
                '•  Finnpark Technopolis',
                '•  P-Ratina',
                '•  Kadunvarsipysäköinti'
            ]
        },
        {
            Id: '4',
            Shape: Shape,
            sTitle: 'Pukukoodi',
            descriptions: [
                'Tulethan paikalle vaatteissa, joissa koet olevasi upeimmillasi!',
                'Muistuttaisimme kuitenkin, että yleisesti ottaen vain morsiammella on kokovalkoinen asu.'
            ]
        },
        {
            Id: '5',
            Shape: Shape,
            sTitle: 'Korona',
            descriptions: [
                'Seuraamme koronatilannetta aktiivisesti ja teemme tarvittaessa muutoksia suunnitelmiimme. Noudatamme voimassa olevia koronaohjeistuksia ja ilmoitamme mahdollisista muutoksista. Toivomme, että vieraat tulisivat paikalle vain terveinä, sillä juhlaväessä on riskiryhmiin kuuluvia. Halukkaille löytyy juhlapaikalta käsienpesumahdollisuus, käsidesiä sekä maskeja.'
            ]
        }
    ]

    const TabsEng = [
        {
            Id: '1',
            tabItem: 'Schedule?',
        },
        {
            Id: '2',
            tabItem: 'Accommodation?',
        },
        {
            Id: '3',
            tabItem: 'Cars?',
        },
        {
            Id: '4',
            tabItem: 'Dresscode?',
        },
        {
            Id: '5',
            tabItem: 'Covid-19?',
        }
    ]

    const InfoEng = [
        {
            Id: '1',
            Shape: Shape,
            sTitle: 'Schedule',
            descriptions: [
                'Our wedding day is on the 24th of September 2022 (Saturday). The day begins with the wedding ceremony at The St. Birgit Memorial Church at 15:00 at Kirkkopolku 1, 37500 Lempäälä.',
                'After the ceremony, the day will continue at restaurant Sorella at Vuolteenkatu 20, 33100 Lempäälä. The drive from the church to the restaurant is about 20 minutes. We would hope for guests to arrive at the venue at around 16:00, but no later than 16:30.',
                'After this, the wedding celebrations will continue for the brave and willing – there will be live music and dancing in the downstairs part of the restaurant (Cellar Club) from 20:00 onwards.',
                'We also hope that those who are capable would join us for breakfast at the Lapland Hotels Arena on Sunday 25.9.2022 at 10.00. Be sure to mention that you are there a part of Ville and Sara’s wedding party, and they’ll direct you to the right place.'
            ]
        },
        {
            Id: '2',
            Shape: Shape,
            sTitle: 'Accommodation',
            image: LocationImageEN,
            descriptions: [
                'We have received a group code from Lapland Hotels Arena for the accommodation of wedding guests! The hotel is right beside the wedding venue and the couple will also be spending their wedding night there.',
                'Guests are to reserve their rooms by themselves with the code “Häät Ville ja Sara” (or “Ville and Sara’s wedding”). The reservation code can only be used when reserving by phone, so call the hotel at +358 40 355 9068, and mention the reservation code. The discount code can be used for reservations on 23.-25.9.2022. If you wish to book a room with the discount code, do so by 25.08.2022! After this, rooms can be booked for regular hotel prices.',
                'Room options:',
                '*please note that there are only a specific amount of rooms available at this hotel for wedding guests.',
                '135 € / room for one / night / Misty Comfort -room',
                '155 € / room for two / night / Misty Comfort -room',
                'or',
                '165 € / room for one / night / Comfort Loft –huone',
                '185 € / room for two / night / Comfort Loft –huone',
                '215 € / room for three or four guests / night / Comfort Loft -room',
                'or',
                '185 € / room for one / night / Mystique DeLuxe -room',
                '205 € / room for two /night / Mystique DeLuxe -room',
                'or',
                '245 € / room for two / night / DeLuxe Spa -room',
                'All prices include a complimentary buffet breakfast and the usage of the communal sauna.',
                'Contacting the hotel: arena@laplandhotels.com or +358 40 355 9068.'
            ]
        },
        {
            Id: '3',
            Shape: Shape,
            sTitle: 'Cars',
            descriptions: [
                'There is a parking lot in front of the church in Lempäälä. If the spots are full, there are more parking spots by Tokmanni (on the other side of the road), or in front of the stores that are a block from the church (Tampereentie 19, 37500 Lempäälä).',
                'Options for parking in Tampere city center (near the restaurant and hotel):',
                '•  Aimo Park Arena',
                '•  Finnpark Tullintori',
                '•  Finnpark Technopolis',
                '•  P-Ratina',
                '•  Parking by the street'
            ]
        },
        {
            Id: '4',
            Shape: Shape,
            sTitle: 'Dresscode',
            descriptions: [
                'Come dressed in whatever you feel the most fabulous in!',
                'However, we do want to remind you that the bride is usually the only person with a white outfit.'
            ]
        },
        {
            Id: '5',
            Shape: Shape,
            sTitle: 'Covid-19',
            descriptions: [
                'We are tracking the Covid-19 situation continuously and we will make any changes accordingly. We will be following current Covid-19 restrictions, and we will notify you if any changes should occur. We would hope that guests arrive healthy, as some wedding guests belong to risk groups. We will provide hand sanitizer, masks, and hand-washing opportunities to those who wish.'
            ]
        }
    ]

    var language = localStorage.getItem("language")
    return (

        <section className="wpo-info-section section-padding" id="info">
            <div className="container">
                {language === 'fi-fi' &&
                    <SectionTitle MainTitle={'No mites sitten...'} />
                }{language === 'en-us' &&
                    <SectionTitle MainTitle={'Well what about...'} />
                }
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        {language === 'fi-fi' &&
                            <div className="tab-area">
                                <div className="tablinks">
                                    <Nav tabs className="service-thumbs-outer">
                                        {Tabs.map((tab, titem) => (
                                            <NavItem key={titem}>
                                                <NavLink
                                                    className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                    onClick={() => { toggle(tab.Id); }}
                                                >
                                                    {tab.tabItem}
                                                    <span className="number">{tab.tabNumber}</span>
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </div>
                                <TabContent activeTab={activeTab}>
                                    {Info.map((info, Sitem) => (
                                        <TabPane tabId={info.Id} key={Sitem}>
                                            <div className="wpo-info-item">
                                                <div className="wpo-info-content">
                                                    <div className="wpo-info-content-inner">
                                                        <h2>{info.sTitle}</h2>
                                                        {info.descriptions.map(description => {
                                                            return <p className="wpo-info-container-text">{description}</p>
                                                        })}
                                                        <div className='Hotel-Map'>
                                                <img src={info.image} alt="" />
                                            </div>
                                                        <div className="border-shape">
                                                            <img src={info.Shape} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </TabPane>
                                    ))}
                                    
                                </TabContent>
                            </div>}
                        {language === 'en-us' &&
                            <div className="tab-area">
                                <div className="tablinks">
                                    <Nav tabs className="service-thumbs-outer">
                                        {TabsEng.map((tab, titem) => (
                                            <NavItem key={titem}>
                                                <NavLink
                                                    className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                                    onClick={() => { toggle(tab.Id); }}
                                                >
                                                    {tab.tabItem}
                                                    <span className="number">{tab.tabNumber}</span>
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </div>
                                <TabContent activeTab={activeTab}>
                                    {InfoEng.map((info, Sitem) => (
                                        <TabPane tabId={info.Id} key={Sitem}>
                                            <div className="wpo-info-item">
                                                <div className="wpo-info-content">
                                                    <div className="wpo-info-content-inner">
                                                        <h2>{info.sTitle}</h2>
                                                        {info.descriptions.map(description => {
                                                            return <p className="wpo-info-container-text">{description}</p>
                                                        })}
                                                        <div className='Hotel-Map'>
                                                <img src={info.image} alt="" />
                                            </div>
                                                        <div className="border-shape">
                                                            <img src={info.Shape} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </TabPane>
                                    ))}
                                </TabContent>
                            </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}


export default InfoSection;