import React from 'react'
import Simg from '../../images/team/manly.jpg'


const ManlySectionTitle = (props) => {
    return(
        <div className="row">
            <div className="wpo-section-title">
                <h2>{props.MainTitle}</h2>
            </div>
        </div>
    )
}

export default ManlySectionTitle;