import React, {Fragment} from 'react';
import { connect } from "react-redux";
import Navbar2 from '../../components/Navbar2'
import Scrollbar from '../../components/scrollbar'
import { addToCart } from "../../store/actions/action";
import api from "../../api";
import Footer from '../../components/footer';
import Product from '../../components/Product';
import LoginPage from '../LoginPage';



const HomePage5 = ({ addToCart}) => {
    const productsArray = api();
  
    const addToCartProduct = (product, qty = 1) => {
      addToCart(product, qty);
    };
  
    const products = productsArray;

    if (localStorage.getItem("userName")!== null){

    return(
        <Fragment>
            <Navbar2/>
            <Product
                addToCartProduct={addToCartProduct}
                products={products}
            />
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
    }
    else {
        return(
            <LoginPage/>
        )
    }
};

export default connect(null, { addToCart })(HomePage5);