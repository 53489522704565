import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import RSVP from '../../components/RSVP';
import EventSection from '../../components/EventSection';
import ReactWebChat from 'botframework-webchat';
import { Style } from '@material-ui/icons';
import { ReactDOM } from 'react';
import LoginPage from '../LoginPage';

const RSVPPage = () => {
    if(localStorage.getItem("userName")!== null){
    return (
        <Fragment>
            <Navbar2 />
            <EventSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
    }
    else {
        return(
            <LoginPage/>
        )
    }
};
export default RSVPPage;
