import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';

import { US } from 'country-flag-icons/react/3x2';
import { FI } from 'country-flag-icons/react/3x2';

const menus = [
    {
        id: 6,
        title: 'Galleria',
        link: '/gallery',
        submenu: [
            {
                id: 61,
                title: 'Galleria',
                link: '/gallery'
            },
            {
                id: 62,
                title: 'Saran Ja Villen Kuvia',
                link: '/gallery'
            },
            {
                id: 63,
                title: 'Vieraiden Kuvia',
                link: '/guest-gallery'
            },
            {
                id: 64,
                title: 'Kuvia Häistä',
                link: '/wedding-gallery'
            },
        ]
    },
    {
        id: 5,
        title: 'Lisätietoa',
        link: '/info',
        submenu: [
            {
                id: 51,
                title: 'Hääinfo',
                link: '/info'
            },
            {
                id: 52,
                title: 'Lahjatoiveita',
                link: '/wish-list'
            },
            {
                id: 53,
                title: 'Kaasot ja Bestmanit',
                link: '/groom-bride'
            },
        ]
    } 
    
]
const menusEng = [
    {
        id: 6,
        title: 'Gallery',
        link: '/gallery',
        submenu: [
            {
                id: 61,
                title: 'Wedding Gallery',
                link: '/wedding-gallery'
            },
            {
                id: 62,
                title: 'Our Gallery',
                link: '/gallery'
            },
            {
                id: 63,
                title: 'Guest gallery',
                link: '/guest-gallery'
            }
        ]
    },
    {
        id: 5,
        title: 'Info',
        link: '/info',
        submenu: [
            {
                id: 51,
                title: 'Wedding info',
                link: '/info'
            },
            {
                id: 52,
                title: 'Gift registry',
                link: '/wish-list'
            },
            {
                id: 53,
                title: 'Best people',
                link: '/groom-bride'
            },
        ]
    }
]

const ChangeLanguagetoFinnish = () => {
    localStorage.setItem("language", "fi-fi")
    window.location.reload();
}

const ChangeLanguagetoEnglish = () => {
    localStorage.setItem("language", "en-us");

    window.location.reload();
}

var language = "fi-fi"
var language = localStorage.getItem("language")


export default class MobileMenu2 extends Component {

    state = {
        isMenuShow: false,
        isOpen: 0,
    }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    setIsOpen = id => () => {
        this.setState({
            isOpen: id === this.state.isOpen ? 0 : id
        })
    }

    render() {

        const { isMenuShow, isOpen } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        }

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                        <div className="clox" onClick={this.menuHandler}><i className="ti-close"></i></div>
                    </div>
                    {language === 'fi-fi' &&

                        <ul className="responsivemenu">
                            <li><Link activeClass="active" to="../home" spy={true} smooth={true} duration={500} >Etusivu</Link> </li>
                            <li><Link activeClass="active" to="../RSVP" spy={true} smooth={true} offset={-80} duration={500} >Hääpäivä</Link></li>
                            <li><Link activeClass="active" to="../story" spy={true} smooth={true} offset={-100} duration={500} >Hääpari</Link></li>
                            {menus.map(item => {
                                return (
                                    <li key={item.id}>
                                        {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                            {item.title}
                                            {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                        </p> : <Link onClick={ClickHandler} to={item.link}>{item.title}</Link>}
                                        {item.submenu ?
                                            <Collapse isOpen={item.id === isOpen}>
                                                <Card>
                                                    <CardBody>
                                                        <ul>
                                                            {item.submenu.map(submenu => (
                                                                <li key={submenu.id}><Link onClick={ClickHandler} className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                            ))}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            : ''}
                                    </li>
                                )
                            })}
                            <li>
                              <ul className="sub-menu">
                                <div className='mobile-language'>Select Language</div>
                                <div className='mobile-flags'>
                                <li className="finnish-flag-mobile"><button onClick={ChangeLanguagetoFinnish}><FI type="button" title="Suomi" width={50}/></button></li>
                                <li className="english-flag-mobile"><button onClick={ChangeLanguagetoEnglish}><US type="button" title="English" width={50} /></button></li>
                                </div>
                              </ul>
                            </li>
                        </ul>
                    }
                    {language === 'en-us' &&

                        <ul className="responsivemenu">
                            <li><Link activeClass="active" to="../home" spy={true} smooth={true} duration={500} >Home</Link> </li>
                            <li><Link activeClass="active" to="../RSVP" spy={true} smooth={true} offset={-80} duration={500} >Wedding</Link></li>
                            <li><Link activeClass="active" to="../story" spy={true} smooth={true} offset={-100} duration={500} >Couple</Link></li>
                            {menusEng.map(item => {
                                return (
                                    <li key={item.id}>
                                        {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                                            {item.title}
                                            {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                                        </p> : <Link onClick={ClickHandler} to={item.link}>{item.title}</Link>}
                                        {item.submenu ?
                                            <Collapse isOpen={item.id === isOpen}>
                                                <Card>
                                                    <CardBody>
                                                        <ul>
                                                            {item.submenu.map(submenu => (
                                                                <li key={submenu.id}><Link onClick={ClickHandler} className="active" to={submenu.link}>{submenu.title}</Link></li>
                                                            ))}
                                                        </ul>
                                                    </CardBody>
                                                </Card>
                                            </Collapse>
                                            : ''}
                                    </li>
                                )
                            })}
                            
                            <li>
                              <ul className="sub-menu">
                                <div className='mobile-language'>Select Language</div>
                                <div className='mobile-flags'>
                                <li className="finnish-flag-mobile"><button onClick={ChangeLanguagetoFinnish}><FI type="button" title="Suomi" width={50}/></button></li>
                                <li className="english-flag-mobile"><button onClick={ChangeLanguagetoEnglish}><US type="button" title="English" width={50} /></button></li>
                                </div>
                              </ul>
                            </li>
                        </ul>
                    }

                </div>
                <div className="showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                        <span className="icon-bar first-angle"></span>
                        <span className="icon-bar middle-angle"></span>
                        <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
            )
    }
}
