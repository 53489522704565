import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import coupleImg1 from '../../images/couple/Couple5.jpg'

class CoupleSection extends Component {

    render() {
        var language = localStorage.getItem("language")
        return (
            <section className="couple-section section-padding" id="couple">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xs-12">
                            <div className="couple-area clearfix">
                                <div className="text-grid bride">
                                    <h3>Ville Orhanen</h3>
                                    {language === 'fi-fi' &&
                                        <p>Ville on kaikkien rakastama hymypoika ja hurmaava hurjapää. Kun Ville päättää jotain tehdä, niin siinä ei olekaan esteenä enää kuin aikataulut. Hän pitää huolen, että elämästä löytyy lapsenmielisyyttä ja naurua.</p>
                                    }
                                    {language === 'en-us' &&
                                        <p>Ville is the life of the party and he’s a bit of a charming daredevil. He will make friends with anyone and he is loved by all. When Ville decides to do something, the only obstacle left is to actually make time for it. He ensures that our life is full of laughter and enjoyment.</p>
                                    }
                                    <div className="social">
                                    </div>
                                </div>
                                <div className="middle-couple-pic">
                                    <div className="middle-couple-pic-inner">
                                        <img src={coupleImg1} alt="" />
                                    </div>
                                </div>
                                <div className="text-grid groom">
                                    <h3>Sara Hekkala</h3>
                                    {language === 'fi-fi' &&
                                        <p>Sara on ihana, järjestelmällinen ja tunnollinen prinsessa. Hän ajattelee aina ensin muita ja vasta sitten itseään. Hän saa meidän kodissa arjen pyörimään ja huolehtii, että myös vapaa-ajalla on mukavaa tekemistä.</p>
                                    }
                                    {language === 'en-us' &&
                                        <p>Sara is a lovely, well-organized, and conscientious princess. She’ll always think of others before herself. She is the planner of the household – she keeps the weekly routine in order. Despite her love for To do -lists and calendars, she won’t say no to some spontaneous fun.</p>
                                    }
                                    <div className="social">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default CoupleSection;