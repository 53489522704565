import { useEffect, useState, useRef } from 'react';
import data from './data.json'

export default () => {

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    setStatus('Loading');
    fetch('https://prod-35.westeurope.logic.azure.com:443/workflows/9a15327848514dc08dfd8cc68e9bbc31/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1zBE1n5jfGtBoeDN-ZW8BgF_E9O1KbjFQhBd91Ogk6A',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ request: { By: localStorage.getItem("userName"), Type: "GetData" } }),
      }
    )
      .then(response => response.json())
      .then(setList)
      .then(() => setStatus('Success'))
      .catch(() => setStatus('Error'));
      
  }, []);
  if (status === 'Loading') {
    return (
      data
    )
  }
  return (
    list
  );
}