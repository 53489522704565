import React from 'react'
import Simg from '../../images/team/brides-icon.png'


const BrideSectionTitle = (props) => {
    return(
        <div className="row">
            <div className="wpo-section-title">
                <h2>{props.MainTitle}</h2>
            </div>
        </div>
    )
}

export default BrideSectionTitle;