import React from "react";
import { Link } from 'react-router-dom'
import SectionTitle from "../SectionTitle";
import ManlySectionTitle from "../BestmenSectionTitle";
import BrideSectionTitle from "../BridesmaidSectionTitle";
import timg1 from '../../images/team/seela.jpg'
import Simg from '../../images/team/manly.png'
import timg2 from '../../images/team/Ella.jpg'
import timg3 from '../../images/team/Lotta.jpg'
import timg5 from '../../images/team/Juho.jpg'
import timg6 from '../../images/team/Tuomas.jpg'
import timg7 from '../../images/team/Jyri.jpg'
import VideoSection from "../VideoSection";

const Brides = [
  {
    id:'1',
    tImg:timg2,
    name:'Ella Hekkala',
    title:'Saran sisko #1', 
    description: ''         
 },
 {
    id:'2',
    tImg:timg1,
    name:'Seela Hekkala',
    title:'Saran sisko #2',     
    description: ''     
 },
 {
    id:'3',
    tImg:timg3,
    name:'Lotta Lönnberg',
    title:'Ystävä ja ex-kämppis',     
    description: ''     
 }
]
const Grooms = [
  {
    id:'1',
    tImg:timg6,
    name:'Tuomas Orhanen',
    title:'Villen veli',
    description: ''     
 },
 {
    id:'2',
    tImg:timg5,
    name:'Juho Viitala',
    title:'Lapsuudenkaveri',   
    description: ''       
 },
 {
    id:'3',
    tImg:timg7,
    name:'Jyri Ursem',
    title:'Opiskelukaveri',    
    description: ''      
 }
]

const BridesEng = [
  {
    id:'1',
    tImg:timg2,
    name:'Ella Hekkala',
    title:'Sara´s sister #1', 
    description: ''         
 },
 {
    id:'2',
    tImg:timg1,
    name:'Seela Hekkala',
    title:'Sara´s sister #2',     
    description: ''     
 },
 {
    id:'3',
    tImg:timg3,
    name:'Lotta Lönnberg',
    title:'Friend & ex-roommate',     
    description: ''     
 }
]
const GroomsEng = [
  {
    id:'1',
    tImg:timg6,
    name:'Tuomas Orhanen',
    title:'Ville´s Brother',
    description: ''     
 },
 {
    id:'2',
    tImg:timg5,
    name:'Juho Viitala',
    title:'Childhood friend',   
    description: ''       
 },
 {
    id:'3',
    tImg:timg7,
    name:'Jyri Ursem',
    title:'Friend from Uni',    
    description: ''      
 }
]

const BrideGrooms = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  }
var language = localStorage.getItem("language")
  return (
    <section className="bride-groom-section">
      <div className="wpo-team-section section-padding pt-20">
        {language === 'fi-fi'&&
        <div className="container">
        <BrideSectionTitle MainTitle={'Kaasot'} />
        <div className="wpo-team-wrap">
          <div className="wpo-team-row">
            {Brides.slice(0, 6).map((Bride, tm) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={tm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Bride.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Bride.name}</h3>
                      <span>{Bride.title}</span>
                      <div className="wpo-team-description">
                      <h6> {Bride.description}</h6>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      {language === 'en-us'&&
        <div className="container">
        <BrideSectionTitle MainTitle={'Bridesmaids'} />
        <div className="wpo-team-wrap">
          <div className="wpo-team-row">
            {BridesEng.slice(0, 6).map((Bride, tm) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={tm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Bride.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Bride.name}</h3>
                      <span>{Bride.title}</span>
                      <div className="wpo-team-description">
                      <h6> {Bride.description}</h6>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      </div>
      <div className="wpo-team-section pt-20 section-padding">
        {language === 'fi-fi' &&
        <div className="container">
        <ManlySectionTitle MainTitle={'Bestmanit'}/>
        <div className="wpo-team-wrap">
          <div className="wpo-team-row">
            {Grooms.slice(0, 6).map((Groom, gm) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={gm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Groom.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Groom.name}</h3>
                      <span>{Groom.title}</span>
                      <div className="wpo-team-description">
                      <h6> {Groom.description}</h6>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      {language === 'en-us' &&
        <div className="container">
        <ManlySectionTitle MainTitle={'Bestmen'}/>
        <div className="wpo-team-wrap">
          <div className="wpo-team-row">
            {GroomsEng.slice(0, 6).map((Groom, gm) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={gm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Groom.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Groom.name}</h3>
                      <span>{Groom.title}</span>
                      <div className="wpo-team-description">
                      <h6> {Groom.description}</h6>
                      </div>
                    </div>
                  </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      </div>
    </section>
  )
}

export default BrideGrooms;