import React, { Component } from "react";
import { connect } from "react-redux";
import MobileMenu2 from "../MobileMenu2";
import min3 from "../../images/love.png";
import { removeFromCart } from "../../store/actions/action";
import { Link } from "react-router-dom";
import {totalPrice} from "../../utils";
import { US } from 'country-flag-icons/react/3x2';
import { FI } from 'country-flag-icons/react/3x2';
import World from '../../images/icon/world.png'




class Header2 extends Component {
  state = {
    isSearchShow: false,
    isCartShow: false,
  }

  searchHandler = () => {
    this.setState({
      isSearchShow: !this.state.isSearchShow
    })
  }
  cartHandler = () => {
    this.setState({
      isCartShow: !this.state.isCartShow
    })
  }

  render() {
    const { isSearchShow, isCartShow } = this.state;

    const SubmitHandler = (e) => {
      e.preventDefault()
    }

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    }

    const Reload = () => {
      window.location.reload();
    }


    const { carts } = this.props;

    const ChangeLanguagetoFinnish = () => {
      localStorage.setItem("language", "fi-fi")
      window.location.reload();
    }

    const ChangeLanguagetoEnglish = () => {
      localStorage.setItem("language", "en-us");

      window.location.reload();
    }

    var language = localStorage.getItem("language")

    return (
      <header id="header" className={this.props.topbarBlock} id="home">
        <div className={`wpo-site-header ${this.props.hclass}`} id="home">
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu2/>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link onClick={[ClickHandler, Reload]} className="navbar-brand logo" to="/home">Orhaset 2022</Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li>
                        <Link onClick={[ClickHandler, Reload]} to="/home">{language === 'fi-fi' && <div>Etusivu</div>}{language === 'en-us' && <div>Home</div>}</Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/RSVP">{language === 'fi-fi' && <div>Hääpäivä</div>}{language === 'en-us' && <div>wedding</div>}</Link>
                      </li>
                      <li>
                        <Link onClick={ClickHandler} to="/story">{language === 'fi-fi' && <div>Hääpari</div>}{language === 'en-us' && <div>Couple</div>}</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/wedding-gallery">{language === 'fi-fi' && <div>Galleria</div>}{language === 'en-us' && <div>Gallery</div>}</Link>
                        <ul className="sub-menu">
                        <li><Link onClick={ClickHandler} to="/wedding-gallery" spy={true} smooth={true} duration={500} >{language === 'fi-fi' && <div>Hääkuvia</div>}{language === 'en-us' && <div>Wedding Gallery</div>}</Link></li>
                        <li><Link onClick={ClickHandler} to="/gallery" spy={true} smooth={true} duration={500} >{language === 'fi-fi' && <div>Saran & Villen Kuvia</div>}{language === 'en-us' && <div>Gallery</div>}</Link></li>
                          <li><Link onClick={ClickHandler} to="/guest-gallery" spy={true} smooth={true} duration={500} >{language === 'fi-fi' && <div>Vieraiden Kuvia</div>}{language === 'en-us' && <div>Guest Gallery</div>}</Link></li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={ClickHandler} to="/info" spy={true} smooth={true} duration={500} >{language === 'fi-fi' && <div>Lisätietoa</div>}{language === 'en-us' && <div>Info</div>}</Link>
                        <ul className="sub-menu">
                          <li><Link onClick={ClickHandler} to="/info">{language === 'fi-fi' && <div>Hääinfo</div>}{language === 'en-us' && <div>Wedding info</div>}</Link></li>
                          <li><Link onClick={ClickHandler} to="/wish-list">{language === 'fi-fi' && <div>Lahjatoiveita</div>}{language === 'en-us' && <div>Gift registry</div>}</Link></li>
                          <li><Link onClick={ClickHandler} to="/groom-bride">{language === 'fi-fi' && <div>Kaasot ja Bestmanit</div>}{language === 'en-us' && <div>Best people</div>}</Link></li>
                        </ul>
                        </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-2">
                  <div className="header-right">
                    <div className="header-search-form-wrapper">
                      <div className="cart-search-contact">
                        <button onClick={this.searchHandler} className="search-toggle-btn"><i
                          className={`${isSearchShow ? 'fi ti-close' : 'fi ti-search'}`}></i></button>
                        <div className={`header-search-form ${isSearchShow ? 'header-search-content-toggle' : ''}`}>
                          <form onSubmit={SubmitHandler}>
                            <div>
                              <input type="text" className="form-control"
                                placeholder="Search here..." />
                              <button type="submit"><i
                                className="fi flaticon-search"></i></button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className="mini-cart">
                      <button
                        onClick={this.cartHandler}
                        className="cart-toggle-btn"
                      >
                        {" "}
                        {language === 'fi-fi' &&
                          <div className="wpo-reserved-text">Varatut lahjat</div>
                        }
                        {language === 'en-us' &&
                          <div className="wpo-reserved-text">Reserved gifts</div>
                        }{" "}
                        <span className="cart-count">{carts.length}</span>
                      </button>
                      <div
                        className={`mini-cart-content ${isCartShow ? "mini-cart-content-toggle" : ""
                          }`}
                      >
                        <button
                          onClick={this.cartHandler}
                          className="mini-cart-close"
                        >
                          <i className="ti-close"></i>
                        </button>
                        <div className="mini-cart-items">
                          {carts &&
                            carts.length > 0 &&
                            carts.map((cart, crt) => (
                              <div className="mini-cart-item clearfix" key={crt}>
                                <div className="mini-cart-item-image">
                                  <span>
                                    <img src={cart.proImg} alt="icon" />
                                  </span>
                                </div>
                                <div className="mini-cart-item-des">
                                  {language === 'fi-fi' &&
                                  <p>{cart.title} </p>
                            }
                            {language === 'en-us' &&
                                  <p>{cart.titleEng} </p>
                            }
                                  <span className="mini-cart-item-price">
                                    ${cart.price} x {" "} {cart.qty}
                                  </span>
                                  <span className="mini-cart-item-quantity">
                                    <button
                                      onClick={() =>
                                        this.props.removeFromCart(cart.id)
                                      }
                                      className="btn btn-sm btn-danger"
                                    >
                                      <i className="ti-close"></i>
                                    </button>{" "}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="mini-cart-action clearfix">
                          <span className="mini-checkout-price">
                            Total: ${totalPrice(carts)}
                          </span>
                        </div>
                        <div className="visible-icon">
                          <img src={min3} alt="icon" />
                        </div>
                      </div>
                    </div>
                    <div className="wpo-flags-toggle">
                      <div className="col-lg-6 col-md-1 col-1">
                        <div id="navbar" className="collapse navbar-collapse navigation-holder">
                          <button className="menu-close"><i className="ti-close"></i></button>
                          <ul className="nav navbar-nav mb-2 mb-lg-0">
                            <li className="menu-item-has-children">
                              {language === 'fi-fi' &&
                                <div className="wpo-world-icon">
                                  <div spy={true} smooth={true} duration={500}><img src={World} alt=""/></div>
                                </div>
                              }
                              {language === 'en-us' &&
                                <div className="wpo-world-icon">
                                  <div spy={true} smooth={true} duration={500}><img src={World} alt=""/></div>
                                </div>
                              }
                              <ul className="sub-menu-flag">
                                <li className="finnish-flag"><button onClick={ChangeLanguagetoFinnish}><FI type="button" title="Suomi" width={40} /></button></li>
                                <li className="english-flag"><button onClick={ChangeLanguagetoEnglish}><US type="button" title="English" width={40} /></button></li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    carts: state.cartList.cart,
  };
};

export default connect(mapStateToProps, { removeFromCart })(Header2);
