import * as types from "./type";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  REMOVE_FROM_COMPARE_LIST,
  ADD_TO_COMPARE,
} from "./type";
import { CardText } from "reactstrap";

var language = localStorage.getItem("language")

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const addToCart = (product, qty, color, size) => (dispatch) => {
  if (language === 'fi-fi') {toast.success("Lahja varattu");}
  else if (language === 'en-us') {toast.success("Gift reserved")}
  dispatch({
    type: types.ADD_TO_CART,
    product,
    qty,
    color,
    size,
  });
const userName = localStorage.getItem("userName")
  const res = fetch('https://prod-35.westeurope.logic.azure.com:443/workflows/9a15327848514dc08dfd8cc68e9bbc31/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1zBE1n5jfGtBoeDN-ZW8BgF_E9O1KbjFQhBd91Ogk6A',
  {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ request: { Type: "ReserveProduct", Product: product, By: userName } })
  }
);
setTimeout(function(){
  window.location.reload();
}, 5000);

};

export const removeFromCart = (product_id) => (dispatch) => {
  if (language === 'fi-fi') {toast.success("Varaus peruttu");}
  else if (language === 'en-us') {toast.success("Reservation cancelled")}
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id
  });
  localStorage.setItem("product_id", product_id)
  const userName = localStorage.getItem("userName")
  const res = fetch('https://prod-35.westeurope.logic.azure.com:443/workflows/9a15327848514dc08dfd8cc68e9bbc31/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=1zBE1n5jfGtBoeDN-ZW8BgF_E9O1KbjFQhBd91Ogk6A',
  {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({ request: { Type: "UnReserveProduct", ProductID: product_id, By: userName } })
      
  }
);
setTimeout(function(){
  window.location.reload();
}, 5000);
};

export const incrementQuantity = (product_id) => (dispatch) => {
  dispatch({
    type: types.INCREMENT_QUANTITY,
    product_id,
  });
};

export const decrementQuantity = (product_id) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_QUANTITY,
    product_id,
  });
};

export const addToWishList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_WISHLIST,
    product,
  });
};

export const removeFromWishList = (id) => (dispatch) => {
  toast.error("Item removed from WishList");
  dispatch({
    type: REMOVE_FROM_WISHLIST,
    id,
  });
};

export const addToCompareList = (product) => (dispatch) => {
  dispatch({
    type: ADD_TO_COMPARE,
    product,
  });
};
export const removeFromCompareList = (product) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_COMPARE_LIST,
    product,
  });
};
