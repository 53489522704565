import React from 'react'
import {Link} from 'react-router-dom'
import erimg from '../../images/error-404.svg'


const Error = (props) => {
    return(
        <section className="error-404-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="content clearfix">
                            <div className="error">
                                <img src={erimg} alt=""/>
                            </div>
                            <div className="error-message">
                                <h3>Hupsista! Tätä sivua ei löytynyt...Kannattaa klikata alla olevasta linkistä takaisin etusivulle.</h3>
                                <Link to="/home" className="theme-btn-s3">Alla oleva linkki.</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Error;