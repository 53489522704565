import React, {Fragment} from "react";
import InfoSection from "../../components/InfoSection";
import Navbar2 from '../../components/Navbar2';
import Information from "../../components/Instructions";
import Footer from "../../components/footer";
import Scrollbar from "../../components/scrollbar";
import LoginPage from "../LoginPage";

const InfoPage =() => {
    if(localStorage.getItem("userName")!== null){

    return(
        <Fragment>
            <Navbar2/>
            <InfoSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
    }
    else {
        return(
            <LoginPage/>
        )
    }
}

export default InfoPage;