import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/GuestGallery/1.jpg'
import hero2 from '../../images/GuestGallery/2.jpg'
import hero3 from '../../images/GuestGallery/3.jpg'

import heros1 from '../../images/slider/shape5.png'
import heros2 from '../../images/slider/shape6.png'



class HeroCarousel extends Component {
    render() {

        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="wpo-box-style-carousel">
                <div className={`wpo-hero-slider ${this.props.heroClass}`}>
                    <div className="swiper-container">
                        <div className="swiper-wrapper">
                            <Slider {...settings}>
                                <div className="hero-slide">
                                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                                    </div>
                                </div>
                                <div className="hero-slide">
                                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero2})` }}>
                                    </div>
                                </div>
                                <div className="hero-slide">
                                    <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero3})` }}>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default HeroCarousel;