import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link, useNavigate } from "react-router-dom";

import { US } from 'country-flag-icons/react/3x2';
import { FI } from 'country-flag-icons/react/3x2';
import World from '../../images/icon/world.png'

import './style.scss';
import { GetApp } from '@material-ui/icons';
import { get } from 'react-scroll/modules/mixins/scroller';
import { Icon } from '@material-ui/core';

var language = 'fi-fi'
var language = localStorage.getItem("language")
const LoginPage = (props) => {

    const push = useNavigate()

    const [value, setValue] = useState({
        name: '',
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const rememberHandler = () => {
        localStorage.setItem("userName", value.name)
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));



    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            setValue({
                name: '',
            });
            validator.hideMessages();

            const name = value.name;

            const res = await fetch('https://prod-25.westeurope.logic.azure.com:443/workflows/38e8a896099642ffac8153648481d34b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xbMHCEauXqhOJcOsU1CKANEkiVT99sUcyshsw0ymXcI',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ user: { name: name } }),
                }
            );
            const { response } = await res.json();

            localStorage.setItem("response", response)

            if (name === response) {
                toast.success('Mahtavaa! Tervetuloa hääsivustollemme :)');
                push('/home');

                localStorage.setItem("userName", value.name)
                window.location.reload();
            }
            else {
                validator.showMessages();
                toast.error('Varmista, että kirjoitit nimesi oikein.');
            }

        } else {
            validator.showMessages();
            toast.error('Nimikenttää ei voi jättää tyhjäksi');
        }
    };

    const ChangeLanguagetoFinnish = () => {
        localStorage.setItem("language", "fi-fi")
        window.location.reload();
    }

    const ChangeLanguagetoEnglish = () => {
        localStorage.setItem("language", "en-us");

        window.location.reload();
    }
    return (

        <Grid className="loginWrapper">
            <Grid className="loginForm">
                <div className="wpo-flags-toggle-login">
                    <div className="finnish-flag-login"><button onClick={ChangeLanguagetoFinnish}><FI type="button" title="Suomi" width={40} /></button></div>
                    <div className="english-flag-login"><button onClick={ChangeLanguagetoEnglish}><US type="button" title="English" width={40} /></button></div>
                </div>
                {language === 'fi-fi' && <div className='login-form-text'><h2>Tunnistaudu tässä</h2>
                <p>Voit tunnistautua kirjoittamalla koko nimesi. Haluamme varmistaa, että sivustolla käy vain tuttua porukkaa.</p></div>}
                {language === 'en-us' && <div className='login-form-text'><h2>Login</h2>
                <p>You can login by writing your full name. We just want to make sure the website is for our guests only.</p></div>}
                {language === 'fi-fi' &&
                <form onSubmit={submitForm}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Maija Meikäläinen"
                                value={value.name}
                                type="text"
                                variant="outlined"
                                name="name"
                                label="Etunimi Sukunimi"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('name', value.name, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Tunnistaudu </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}
                {language === 'en-us' &&
                <form onSubmit={submitForm}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Jane Doe"
                                value={value.name}
                                type="text"
                                variant="outlined"
                                name="name"
                                label="Firstname Lastname"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('name', value.name, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtnTheme" type="submit">Login </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default LoginPage;