import React, { useState } from 'react';
import SectionTitle from '../../components/SectionTitle'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import sImg1 from '../../images/story/GAP1.jpg'
import sImg2 from '../../images/story/Vuores1.jpg'
import sImg3 from '../../images/story/exchange.jpg'
import sImg4 from '../../images/story/Together1.jpg'
import sImg5 from '../../images/story/Proposal.jpg'

import sIcon1 from '../../images/story/1.png'
import sIcon2 from '../../images/story/2.png'
import sIcon3 from '../../images/story/3.png'
import sIcon4 from '../../images/story/4.png'


import Shape from '../../images/story/shape.jpg'


const StorySection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }


    const Tabs = [
        {
            Id: '1',
            tabItem: 'Missä tapasimme',
        },
        {
            Id: '2',
            tabItem: 'Ensimmäinen asunto',
        },
        {
            Id: '3',
            tabItem: 'Vaihto',
        },
        {
            Id: '4',
            tabItem: 'Yhteinen koti',
        },
        {
            Id: '5',
            tabItem: 'Kosinta',
        }
    ]

    const StoryEng = [
        {
            Id: '1',
            sImg: sImg1,
            Shape: Shape,
            sTitle: 'The first time we met',
            date: '6.9.2017',
            description: 'In 2017, we both received our acceptance letter to attend school in Mikkeli, and so Sara moved from Espoo, and Ville said goodbye to his life in Jakarta to move to Mikkeli. By chance, we both got student apartments from the middle of nowhere (a few kilometers outside Mikkeli city center…). And since we’d moved there for school, we obviously had to attend school and a bunch of other extracurricular activities. In the beginning, the easiest way to get everywhere was by bus with other fellow students (we met for the first time on a bus). However, we soon realized that the bus was slow, annoying, and got us late quite too many times. Thus, we started driving around in Ville’s parents’ borrowed car (which we’re still borrowing).'
        },
        {
            Id: '2',
            sImg: sImg2,
            Shape: Shape,
            sTitle: 'We moved here in the summer of 2019',
            date: '5.6.2019',
            description: 'After Mikkeli, our journey continued to Tampere into our first shared apartment. We lived in the lovely apartment for two months in the summer of 2019, after which we went on our first trip abroad together, no less than across the Atlantic to the States with Ville’s parents and brother.'
        },
        {
            Id: '3',
            sImg: sImg3,
            Shape: Shape,
            sTitle: 'And now we’re supposed to be living apart?!',
            date: '1.8.2019',
            description: 'During the autumn of 2019, we both left Mikkeli to complete our exchange programs. Sara traveled to the other side of the Atlantic yet again, but this time to Canada. Ville, on the other hand, moved to the beautiful country of Ireland. Although the 4-month exchange was an unforgettable experience for both, we were still counting down the days when we would see each other again. When Zoom and Google Teams weren’t quite a thing yet, our movie nights together were held via Skype. Skype also eased the pain of being apart temporarily and so we would stay on the call for whole nights, and you could hear familiar snoring sounds from  4000 kilometers away.'
        },
        {
            Id: '4',
            sImg: sImg4,
            Shape: Shape,
            sTitle: 'And we’re back!',
            date: '29.12.2019',
            description: 'Since December 2019, our home has been located in Niittykumpu, Espoo. Our first apartment in Espoo was homely and compact – cozy, as realtors would call it. After the first year of Covid-19, our apartment changed to a slightly larger apartment in the same apartment complex (with a sauna!) just four floors higher than the previous one. We were headed up in life.'
        },
        {
            Id: '5',
            sImg: sImg5,
            Shape: Shape,
            sTitle: 'Will you marry me?',
            date: '17.10.2021',
            description: 'In the early fall of 2021 Ville finally got it. This is where he wanted to be for the rest of his life – with Sara. So this time, it was Ville’s turn to start planning. Messages were sent to Sara’s sister and her best friend about going ring shopping. Yowza! After weeks of planning, Ville finally got the chance as Sara’s dad for his blessing. Before Ville could finish his question, he had been given an affirmative answer. The men shook hands on it. Although there had been conversations and plans for a future together, Sara was completely in the dark about what was happening. One weekend in mid-October, Sara headed towards Kuopio to celebrate one of her friend’s birthday parties. This was the perfect opportunity for Ville to buy a ring. Ville made big, romantic plans for the proposal: he was going to go grocery shopping, make dinner and prepare Sara’s favorite dessert, after which he would say a couple of well-chosen words and pop the question. Naturally, things did not go as planned. Sara, being the organized person she is, had arranged herself a group meeting for school after dinner. In her defense, she was unaware of a potential proposal going down. Nonetheless, later on in the evening after some waiting, while the two were unpacking Sara’s suitcase from her weekend trip, Ville was able to get down on one knee and say the three words. She said yes on 17.10.2021!'
        }
    ]

    const TabsEng = [
        {
            Id: '1',
            tabItem: 'Where we met',
        },
        {
            Id: '2',
            tabItem: 'Our first apartment',
        },
        {
            Id: '3',
            tabItem: 'Exchange',
        },
        {
            Id: '4',
            tabItem: 'First proper home together',
        },
        {
            Id: '5',
            tabItem: 'Proposal',
        }
    ]

    const Story = [
        {
            Id: '1',
            sImg: sImg1,
            Shape: Shape,
            sTitle: 'Eka kerta kun tavattiin',
            date: '6.9.2017',
            description: 'Vuonna 2017 muutimme molemmat Mikkeliin opintojen perässä, Sara Espoosta ja Ville Jakartasta. Sattumalta olimme molemmat saaneet opiskelija-asunnot Mikkelin keskustan ulkopuolelta, eli keskeltä ei-mitään. Koulussa ja tapahtumissa oli kuitenkin pakko käydä, ja matka taittui helpoiten muiden opiskelijakavereiden kanssa bussilla – ensimmäinen tapaamisemme olikin bussissa. Kun bussi todettiin hitaaksi, ja kun siitä myöhästyttiin tarpeeksi monta kertaa, alkoivat matkat taittumaan yhdessä Villen vanhemmilta lainatulla autolla (joka on edelleen lainassa).'
        },
        {
            Id: '2',
            sImg: sImg2,
            Shape: Shape,
            sTitle: 'Tänne me muutettiin kesällä 2019',
            date: '5.6.2019',
            description: 'Mikkelin jälkeen matkamme jatkui Tampereen Vuorekseen ensimmäiseen yhteiseen asuntoomme. Asunto palveli meitä kahden ihanan kuukauden verran kesällä 2019, jonka jälkeen suuntasimme yhdessä Villen vanhempien ja veljen kanssa Atlantin toiselle puolelle ensimmäiselle yhteiselle ulkomaanmatkalle. '
        },
        {
            Id: '3',
            sImg: sImg3,
            Shape: Shape,
            sTitle: 'Sitten asutaankin erillään!',
            date: '1.8.2019',
            description: 'Syksyllä 2019 lähdimme molemmat suorittamaan vaihto-opintojamme. Sara matkasi jo toiseen otteeseen sinä kesänä rapakon toiselle puolelle, vaikkakin tällä kertaa Kanadaan. Villen matka vei taas vihreään Irlantiin. Vaikka vaihdossa käyminen oli molemmille unohtumaton kokemus, laskimme silti puhelimessa päiviä jälleennäkemiseen. Kun Zoomista ja Teamsista ei ollut vielä tietoa, yhteisiä leffailtoja toteutettiin Skypen kautta. Skype hoiti myös ikävää välillä olemalla yön yli auki, jolloin pääsi kuuntelemaan toisen kotoisaa kuorsausta 4000 kilometrin päästä.'
        },
        {
            Id: '4',
            sImg: sImg4,
            Shape: Shape,
            sTitle: 'Ja taas yhdessä!',
            date: '29.12.2019',
            description: 'Joulukuusta 2019 lähtien yhteinen kotimme on löytynyt Espoon Niittykummusta. Ensimmäinen Niittykummun koti oli kodikas ja tiivis. Ensimmäisen koronavuoden jälkeen asunto kuitenkin vaihtui suurempaan kotikoloon, joka löytyi saman taloyhtiön yläkerrasta. Suunnattiin elämässä ylöspäin.'
        },
        {
            Id: '5',
            sImg: sImg5,
            Shape: Shape,
            sTitle: 'Mennäänkö naimisiin?',
            date: '17.10.2021',
            description: 'Alkusyksystä 2021 Ville sen tajusi. Tässä me halutaan olla loppuelämä, toistemme kanssa. Ville pisti suunnitelmat liikkeelle: viestiä Saran siskoille ja parhaalle ystävälle, milloin mennään sormusostoksille. Useita viikkoja kestänyt suunnittelu huipentui, kun Ville vähän vihjaillen pyysi lupaa kosintaan Saran isältä. Lupa tuli. Vaikka yhteisestä tulevaisuudesta oli ollut puhetta, Sara ei aavistanut Villen suunnitelmista mitään. Yhtenä lokakuisena viikonloppuna Sara lähti Kuopioon viettämään ystävänsä syntymäpäiviä, ja Villellä oli vihdoin mahdollisuus lähteä huippusalaisille sormusostoksille. Ville laati samalla suuria romanttisia suunnitelmia kosinnalle sunnuntai-illalle: hän siivoaa kotona, käy ruokakaupassa, tekee Saran lempiruokaa jälkiruuan kera, ja sitten hän lausuu muutaman kauniin sanan ja kosii. Kuten voikin arvata, nämä suunnitelmat eivät menneet niinkuin Strömsössä. Sara oli sopinut itselleen mm. koulutöihin liittyvän ryhmätapaamisen illallisen päälle, kun ei tiennyt miten tärkeä ilta olisi tulossa. Muutaman mutkan kautta ja pienen improvisoinnin jälkeen Ville kuitenkin onnistui suunnitelmissaan ja kosi (romanttisesti) 17.10.2021.'
        }
    ]


    var language = localStorage.getItem("language")
    return (

        <section className="wpo-story-section section-padding" id="story">
            <div className="container">
                {language === 'fi-fi' &&
                    <SectionTitle MainTitle={'Tapahtumia matkan varrelta'} />
                }{language === 'en-us' &&
                    <SectionTitle MainTitle={'Our story'} />
                }
                <div className="row align-items-center justify-content-center">
                    <div className="col col-lg-12 col-12">
                        {language === 'fi-fi' &&
                        <div className="tab-area">
                        <div className="tablinks">
                            <Nav tabs className="service-thumbs-outer">
                                {Tabs.map((tab, titem) => (
                                    <NavItem key={titem}>
                                        <NavLink
                                            className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                            onClick={() => { toggle(tab.Id); }}
                                        >
                                            {tab.tabItem}
                                            <span className="number">{tab.tabNumber}</span>
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </div>
                        <TabContent activeTab={activeTab}>
                            {Story.map((story, Sitem) => (
                                <TabPane tabId={story.Id} key={Sitem}>
                                    <div className="wpo-story-item">
                                        <div className="wpo-story-img">
                                            <img src={story.sImg} alt="" />
                                        </div>
                                        <div className="wpo-story-content">
                                            <div className="wpo-story-content-inner">
                                                <div className='wpo-story-content-title'>
                                                    <h2>{story.sTitle}</h2>
                                                </div>
                                                <span>{story.date}</span>
                                                <p>{story.description}</p>
                                                <div className="border-shape">
                                                    <img src={story.Shape} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>}
                    {language === 'en-us' &&
                    <div className="tab-area">
                    <div className="tablinks">
                        <Nav tabs className="service-thumbs-outer">
                            {TabsEng.map((tab, titem) => (
                                <NavItem key={titem}>
                                    <NavLink
                                        className={`service-thumb ${classnames({ active: activeTab === tab.Id })}`}
                                        onClick={() => { toggle(tab.Id); }}
                                    >
                                        {tab.tabItem}
                                        <span className="number">{tab.tabNumber}</span>
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </div>
                    <TabContent activeTab={activeTab}>
                        {StoryEng.map((story, Sitem) => (
                            <TabPane tabId={story.Id} key={Sitem}>
                                <div className="wpo-story-item">
                                    <div className="wpo-story-img">
                                        <img src={story.sImg} alt="" />
                                    </div>
                                    <div className="wpo-story-content">
                                        <div className="wpo-story-content-inner">
                                            <div className='wpo-story-content-title'>
                                                <h2>{story.sTitle}</h2>
                                            </div>
                                            <span>{story.date}</span>
                                            <p>{story.description}</p>
                                            <div className="border-shape">
                                                <img src={story.Shape} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        ))}
                    </TabContent>
                </div>}
                    </div>
                </div>
            </div>
        </section>
    )
}


export default StorySection;