import React from 'react'
import SectionTitleClean from '../SectionTitleClean'
import sImg1 from '../../images/event/Kirkko2.jpg'
import sImg2 from '../../images/event/Sorella.jpg'
import sImg3 from '../../images/event/Kellari2.jpg'
import LocationMap from '../Modal'
import LocationMap2 from '../Modal2'
import LocationMap3 from '../Modal3'



const Events = [
    {
        Simg: sImg1,
        title: 'Kirkko ja Vihkiminen',
        li1: 'Lauantaina 24.9.2022 15.00–15.30',
        li2: 'Pyhän Birgitan kirkko, Kirkkopolku 1, 37500 Lempäälä',
        location: <LocationMap />,
        galleryLink: '/wedding-gallery'
    },
    {
        Simg: sImg2,
        title: 'Hääjuhla ja illallinen',
        li1: 'Lauantaina 24.9.2022 16.00–20.00',
        li2: 'Ravintola Sorella, Vuolteenkatu 20 (yläkerta), 33100 Tampere',
        location: <LocationMap2 />,
        galleryLink: '/wedding-gallery'
    },
    {
        Simg: sImg3,
        title: 'JATKOT JA ILLANVIETTOA',
        li1: 'Lauantaina 24.9.2022 20.00–2.00',
        li2: 'Cellar Club, Vuolteenkatu 20 (alakerta), 33100 Tampere',
        location: <LocationMap3 />,
        galleryLink: '/wedding-gallery'
    },

]
const EventsEng = [
    {
        Simg: sImg1,
        title: 'CHURCH AND WEDDING CEREMONY',
        li1: 'Saturday 24.9.2022 15.00–15.30',
        li2: 'The St. Birgit Memorial Church, Kirkkopolku 1, 37500 Lempäälä',
        location: <LocationMap />,
        galleryLink: '/wedding-gallery'
    },
    {
        Simg: sImg2,
        title: 'WEDDING AND DINNER',
        li1: 'Saturday 24.9.2022 16.00–20.00',
        li2: 'Restaurant Sorella, Vuolteenkatu 20 (upstairs), 33100 Tampere',
        location: <LocationMap2 />,
        galleryLink: '/wedding-gallery'
    },
    {
        Simg: sImg3,
        title: 'AFTERPARTY',
        li1: 'Saturday 24.9.2022 20.00–2.00',
        li2: 'Cellar Club, Vuolteenkatu 20 (downstairs), 33100 Tampere',
        location: <LocationMap3 />,
        galleryLink: '/wedding-gallery'
    },

]

const EventSection = (props) => {
    var language = localStorage.getItem("language")
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                {language === 'fi-fi' &&
                    <SectionTitleClean MainTitle={'HÄÄPÄIVÄ'} />
                }
                {language === 'en-us' &&
                    <SectionTitleClean MainTitle={'WEDDING DAY'} />
                }
                <div className="wpo-event-wrap">
                    {language === 'fi-fi' &&
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img">
                                        <img src={event.Simg} alt="" />
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.location}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {language === 'en-us' &&
                    <div className="row">
                        {EventsEng.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img">
                                        <img src={event.Simg} alt="" />
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.location}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div>
            </div>
        </section>
    )
}

export default EventSection;