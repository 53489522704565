import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import CleanSectionTitle from '../../components/SectionTitleClean'
import LoginPage from '../LoginPage';
import WeddingPhotos from '../../components/WeddingPhotos';

var language = localStorage.getItem("language")
const GalleryPage = () => {
    if (localStorage.getItem("userName") !== null) {
        return (
            <Fragment>
                <Navbar2 />
                {language === 'fi-fi' &&
                    <PageTitle pageTitle={'Hää Galleria'} pagesub={'Gallery'} />
                }
                {language === 'en-us' &&
                    <PageTitle pageTitle={'Wedding Gallery'} pagesub={'Gallery'} />
                }
                {language === 'fi-fi' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Kuvia Häistä'} />
                    </div>
                }
                {language === 'en-us' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Pictures from the big day'} />
                    </div>
                }
                <WeddingPhotos />
                <Footer />
                <Scrollbar />
            </Fragment>
        )
    }
    else {
        return (
            <LoginPage />
        )
    }

};
export default GalleryPage;
