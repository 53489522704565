import React, { Component } from 'react'
import SectionTitleClean from '../SectionTitleClean'
import createDirectLine from 'botframework-webchat/lib/createDirectLine'
import Rhobot from '../Rhobot'

import vec1 from '../../images/contact/1.png'
import vec2 from '../../images/contact/2.png'

const language = localStorage.getItem("language")
class RSVP extends Component {

  render() {

    return (

      <section className={`wpo-contact-section ${this.props.pt}`} id="RSVP">
        <div className="container">
          <div className="wpo-contact-section-wrapper">
            <div className="wpo-contact-form-area">
              <div className='wpo-contact-section-title'>
                {language === 'fi-fi' &&
                <SectionTitleClean MainTitle={'Pääsetkö paikalle?'} />
    }
    {language === 'en-us' &&
                <SectionTitleClean MainTitle={'RSVP'} />
    }
              </div>
              <div className="border-style"></div>
            </div>
            <div className="vector-1">
              <img src={vec1} alt="" />
            </div>
            <div className="vector-2">
              <img src={vec2} alt="" />
            </div>
          </div>
        </div>
      </section>
    )
  }

}
export default RSVP;
