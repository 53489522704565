import React from 'react'
import TimeCountDown from '../countdown'
import TimeCountUp from '../countup'
import WeddingDay from '../countdown/date'
import hImg1 from '../../images/slider/shape.png'
import hImg2 from '../../images/slider/shape2.png'
import hImg3 from '../../images/slider/Couple8.jpg'
import hImg4 from '../../images/slider/shape3.png'
import hImg5 from '../../images/contact/2.png'
import vec1 from '../../images/contact/1.png'
import vec2 from '../../images/contact/3.png'

const currentTime = new Date().getTime();
const countdownDate = new Date(WeddingDay).getTime();

const Hero = (props) => {
    var language = localStorage.getItem("language")
    return (
        <section className="static-hero">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-xl-8 col-lg-6 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div className="shape-1"><img src={hImg1} alt="hImg1" /></div>
                                    <div data-swiper-parallax="300" className="slide-title">
                                        <h2>Ville & Sara</h2>
                                    </div>
                                    {countdownDate >= currentTime &&
                                        <div data-swiper-parallax="400" className="slide-text">
                                            {language === 'fi-fi' &&
                                                <p> Me mennään naimisiin 24. syyskuuta 2022 </p>
                                            }
                                            {language === 'en-us' &&
                                                <p> WE ARE GETTING MARRIED IN... </p>
                                            }
                                        </div>
                                    }
                                    {countdownDate <= currentTime &&
                                        <div data-swiper-parallax="400" className="slide-text">
                                            {language === 'fi-fi' &&
                                                <p> Onnellisesti naimisissa jo... </p>
                                            }
                                            {language === 'en-us' &&
                                                <p> Happily married for... </p>
                                            }
                                        </div>
                                    }
                                    <div className="wpo-wedding-date">
                                        <div className="clock-grids">
                                            {countdownDate <= currentTime &&
                                                <TimeCountUp />
                                            }
                                            {countdownDate >= currentTime &&
                                                <TimeCountDown />
                                            }
                                        </div>
                                    </div>
                                    <div className="shape-2"><img src={hImg2} alt="hImg2" /></div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="static-hero-right">
                <div className="static-hero-img">
                    <div className="static-hero-img-inner">
                        <img src={hImg3} alt="hImg3" />
                    </div>
                    <div className="static-hero-shape-1"><img src={vec1} alt="vec1" /></div>
                    <div className="static-hero-shape-2"><img src={vec2} alt="vec2" /></div>
                </div>
            </div>
        </section>
    )
}

export default Hero;