import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage from '../HomePage'
import HomePage5 from '../HomePage5';
import StoryPage from '../StoryPage'
import RSVPPage from '../RSVPPage'
import GalleryPage from '../GalleryPage'
import BrideGroomPage from '../BrideGroomPage'
import ErrorPage from '../ErrorPage'
import LoginPage from '../LoginPage'
import InfoPage from '../InfoPage';
import GuestGalleryPage from '../GuestGalleryPage';
import WeddingGalleryPage from '../WeddingGallery';

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginPage/>} />
          <Route path='home' element={<Homepage/>} />
          <Route path='wish-list' element={<HomePage5/>} />
          <Route path='story' element={<StoryPage/>} />
          <Route path='rsvp' element={<RSVPPage/>} />
          <Route path='gallery' element={<GalleryPage/>} />
          <Route path='groom-bride' element={<BrideGroomPage/>} />
          <Route path='404' element={<ErrorPage/>} />
          <Route path='login' element={<LoginPage/>} />
          <Route path='Info' element={<InfoPage/>} />
          <Route path='guest-gallery' element={<GuestGalleryPage/>} />
          <Route path='wedding-gallery' element={<WeddingGalleryPage/>} />
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
