import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import BrideGrooms from '../../components/BrideGrooms';
import LoginPage from '../LoginPage';

const BrideGroomPage =() => {
    if(localStorage.getItem("userName")!== null){
    return(
        <Fragment>
            <Navbar2/>
            <BrideGrooms/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
    }
    else {
        return(
            <LoginPage/>
        )
    }
};
export default BrideGroomPage;
