
import pImg2 from '../../images/portfolio/12.jpg'
import pImg3 from '../../images/portfolio/3.jpg'
import pImg4 from '../../images/portfolio/4.jpg'
import pImg5 from '../../images/portfolio/5.jpg'
import pImg8 from '../../images/portfolio/8.jpg'
import pImg9 from '../../images/portfolio/9.jpg'
import pImg10 from '../../images/portfolio/10.jpg'
import pImg11 from '../../images/portfolio/11.jpg'
import pImg12 from '../../images/portfolio/13.jpg'
import pImg13 from '../../images/portfolio/2.jpg'
import pImg14 from '../../images/portfolio/14.jpg'
import pImg15 from '../../images/portfolio/15.jpg'
import pImg16 from '../../images/portfolio/16.jpg'
import pImg18 from '../../images/portfolio/18.jpg'
import pImg19 from '../../images/portfolio/19.jpg'
import pImg20 from '../../images/portfolio/20.jpg'
import pImg21 from '../../images/portfolio/21.jpg'
import pImg22 from '../../images/portfolio/22.jpg'
import pImg24 from '../../images/portfolio/24.jpg'
import pImg25 from '../../images/portfolio/25.jpg'
import pImg26 from '../../images/portfolio/26.jpg'
import pImg28 from '../../images/portfolio/28.jpg'
import pImg29 from '../../images/portfolio/29.jpg'
import pImg33 from '../../images/portfolio/33.jpg'
import pImg34 from '../../images/portfolio/34.jpg'
import pImg35 from '../../images/portfolio/35.jpg'
import pImg36 from '../../images/portfolio/36.jpg'

import React from 'react';
import ReactDOM from 'react-dom';

import ImageGallery from 'react-image-gallery'


class OurPictures extends React.Component {

  constructor() {
    super();
    this.state = {
      showIndex: true,
      showBullets: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: true,
      showNav: true,
      isRTL: false,
      slideDuration: 50,
      slideInterval: 2000,
      slideOnThumbnailOver: false,
      thumbnailPosition: 'bottom',
      showVideo: {},
      useWindowKeyDown: true,
    };

    this.images = [
      {
        original: pImg2,
        thumbnail: pImg2,
      },
      {
        original: pImg3,
        thumbnail: pImg3,
      },{
        original: pImg4,
        thumbnail: pImg4,
      },{
        original: pImg5,
        thumbnail: pImg5,
      },
      {
        original: pImg4,
        thumbnail: pImg4,
      },
      {
        original: pImg8,
        thumbnail: pImg8,
      },
      {
        original: pImg9,
        thumbnail: pImg9,
      },
      {
        original: pImg10,
        thumbnail: pImg10,
      },
      {
        original: pImg11,
        thumbnail: pImg11,
      },
      {
        original: pImg12,
        thumbnail: pImg12,
      },
      {
        original: pImg13,
        thumbnail: pImg13,
      },
      {
        original: pImg14,
        thumbnail: pImg14,
      },
      {
        original: pImg15,
        thumbnail: pImg15,
      },
      {
        original: pImg16,
        thumbnail: pImg16,
      },
      {
        original: pImg18,
        thumbnail: pImg18,
      },
      {
        original: pImg19,
        thumbnail: pImg19,
      },
      {
        original: pImg20,
        thumbnail: pImg20,
      },{
        original: pImg21,
        thumbnail: pImg21,
      },{
        original: pImg22,
        thumbnail: pImg22,
      },
      {
        original: pImg24,
        thumbnail: pImg24,
      },
      {
        original: pImg25,
        thumbnail: pImg25,
      },
      {
        original: pImg26,
        thumbnail: pImg26,
      },
      {
        original: pImg28,
        thumbnail: pImg28,
      },
      {
        original: pImg29,
        thumbnail: pImg29,
      },
      {
        original: pImg33,
        thumbnail: pImg33,
      },
      {
        original: pImg34,
        thumbnail: pImg34,
      },
      {
        original: pImg35,
        thumbnail: pImg35,
      },
      {
        original: pImg36,
        thumbnail: pImg36,
      }
    ]
  }

  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    if (event.target.value > 0) {
      this.setState({[state]: event.target.value});
    }
  }

  _handleCheckboxChange(state, event) {
    this.setState({[state]: event.target.checked});
  }

  _handleThumbnailPositionChange(event) {
    this.setState({thumbnailPosition: event.target.value});
  }


  _resetVideo() {
    this.setState({showVideo: {}});

    if (this.state.showPlayButton) {
      this.setState({showGalleryPlayButton: true});
    }

    if (this.state.showFullscreenButton) {
      this.setState({showGalleryFullscreenButton: true});
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({showGalleryPlayButton: false});
      }

      if (this.state.showFullscreenButton) {
        this.setState({showGalleryFullscreenButton: false});
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        {
          this.state.showVideo[item.embedUrl] ?
            <div className='video-wrapper'>
                <a
                  className='close-video'
                  onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
                >
                </a>
                <iframe
                  width='560'
                  height='315'
                  src={item.embedUrl}
                  frameBorder='0'
                  allowFullScreen
                >
                </iframe>
            </div>
          :
            <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
              <div className='play-button'></div>
              <img className='image-gallery-image' src={item.original} />
              {
                item.description &&
                  <span
                    className='image-gallery-description'
                    style={{right: '0', left: 'initial'}}
                  >
                    {item.description}
                  </span>
              }
            </a>
        }
      </div>
    );
  }

  render() {
    return (

      <section className='app'>
        <ImageGallery
          ref={i => this._imageGallery = i}
          items={this.images}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={this.state.showFullscreenButton && this.state.showGalleryFullscreenButton}
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          slideOnThumbnailOver={this.state.slideOnThumbnailOver}
          additionalClass="app-image-gallery"
          useWindowKeyDown={this.state.useWindowKeyDown}
        />
      </section>
    );
  }
}

ReactDOM.render(<OurPictures/>, document.getElementById('root'));

export default OurPictures;
