import React from "react";
import { Link, NavLink } from "react-router-dom";
import SectionTitle from '../../components/SectionTitle'
import pImg1 from '../../images/product/funnyButTrue.jpg'


const Product = ({ products, addToCartProduct }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

var language = localStorage.getItem("language")

  return (
    <section className="wpo-product-section">
      <div className="container">
      {language === 'fi-fi' &&
        <SectionTitle MainTitle={'Näistä lahjoista olisi iloa meille!'} />
  }
  {language === 'en-us' &&
        <SectionTitle MainTitle={'We would love these gifts!'} />
  }
        <div className="wpo-product-headings"> 
        {language === 'fi-fi' && <div>
        <p className="wpo-product-heading">Paras lahja on tietenkin päästä juhlimaan häitämme teidän kanssanne. Jos meitä kuitenkin haluaa muistaa erityisemmin, alla olevista lahjoista olisi meille iloa. Jos haluat muistaa meitä jollain toisella tapaa, anna palaa!</p>
        <p className="wpo-product-heading-2">Lahjan voi osoittaa häätilillemme tai tuoda mukanaan hääjuhlaan. Tyyli vapaa, muttei pakollinen.</p>
        <p className="wpo-product-heading-3">Häätili: FI90 5780 4120 1065 85 / Sara Hekkala</p>
        </div>}
        {language === 'en-us' && <div>
        <p className="wpo-product-heading">The best gift, of course, is to have you come and celebrate our wedding day with us. However, if you wish to give us something for our big day, these gifts would bring us the most joy. If none of these gifts seem like your pick, feel free to get creative!</p>
        <p className="wpo-product-heading-2">Gifts can be directed to our wedding account with a message, or you can bring them with you to the wedding. (Please note that a gift is totally not necessary!)</p>
        <p className="wpo-product-heading-3">Wedding account: FI90 5780 4120 1065 85 / Sara Hekkala</p>
        </div>}
        </div>
        {language === 'fi-fi' &&
        <div className="wpo-product-wrap">
        <div className="row">
          {products.length > 0 &&
            products.slice(0, 30).map((product, pitem) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={pitem}>
                <div className="wpo-product-item">
                  <div className="wpo-product-img">
                    <div>
                      {product.Button === 'Varaa' && <div className="wpo-product-available">
                        <img src={product.proImg} alt="" />
                        <button id={product.Button}
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title=""
                          onClick={() => addToCartProduct(product)}
                        >
                          <div className="wpo-product-img-reserved">{product.Button}</div>
                        </button>
                      </div>}
                      {product.Button === 'Varattu' && 
                        <div className="wpo-product-image-reserved">
                        <img src={product.proImg} alt="" />
                        <div className="wpo-product-text-reserved">Varattu</div>
                        </div>
                        }
                        
                    </div>
                  </div>
                  <div className="wpo-product-text">
                    <h3><a href={product.link} target="_blank">{product.title}</a></h3>
                    <span>€ {product.price}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>}
      {language === 'en-us' &&
        <div className="wpo-product-wrap">
        <div className="row">
          {products.length > 0 &&
            products.slice(0, 30).map((product, pitem) => (
              <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={pitem}>
                <div className="wpo-product-item">
                  <div className="wpo-product-img">
                    <div>
                      {product.ButtonEng === 'Reserve' && <div className="wpo-product-available">
                        <img src={product.proImg} alt="" />
                        <button id={product.ButtonEng}
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title=""
                          onClick={() => addToCartProduct(product)}
                        >
                          <div className="wpo-product-img-reserved">{product.ButtonEng}</div>
                        </button>
                      </div>}
                      {product.ButtonEng === 'Reserved' && 
                        <div className="wpo-product-image-reserved">
                        <img src={product.proImg} alt="" />
                        <div className="wpo-product-text-reserved">Reserved</div>
                        </div>
                        }
                        
                    </div>
                  </div>
                  <div className="wpo-product-text">
                    <h3><a href={product.link} target="_blank">{product.titleEng}</a></h3>
                    <span>€ {product.price}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>}
        <div className="Fun-stuff">
          <img src={pImg1} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Product;
