import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import DemoCarousel from '../../components/Slider';
import GuestGallery from '../../components/GuestGallery';
import HeroCarousel from '../../components/heroCarousel';
import SectionTitle from '../../components/SectionTitle';
import CleanSectionTitle from '../../components/SectionTitleClean';
import LoginPage from '../LoginPage';

var language = localStorage.getItem("language")
const GuestGalleryPage =() => {
    if (localStorage.getItem("userName")!==null) {
    return(
        <Fragment>
            <Navbar2/>
            {language === 'fi-fi' &&
                    <PageTitle pageTitle={'Vieraiden galleria'} pagesub={'Gallery'} />
                }
                {language === 'en-us' &&
                    <PageTitle pageTitle={'Guest gallery'} pagesub={'Gallery'} />
                }
                {language === 'fi-fi' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Vieraiden lähettämiä kuvia'} />
                    </div>
                }
                {language === 'en-us' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Pictures from the guests'} />
                    </div>
                }
            <DemoCarousel/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
}
else {
    return(
        <LoginPage/>
    )
}
};

export default GuestGalleryPage;
