import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import PortfolioSection3 from '../../components/PortfolioSection3';
import OurPictures from '../../components/Slider2'
import CleanSectionTitle from '../../components/SectionTitleClean'
import LoginPage from '../LoginPage';

var language = localStorage.getItem("language")
const GalleryPage = () => {
    if (localStorage.getItem("userName") !== null) {
        return (
            <Fragment>
                <Navbar2 />
                {language === 'fi-fi' &&
                    <PageTitle pageTitle={'Saran & Villen Galleria'} pagesub={'Gallery'} />
                }
                {language === 'en-us' &&
                    <PageTitle pageTitle={'Our Gallery'} pagesub={'Gallery'} />
                }
                {language === 'fi-fi' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Kuvia matkan varrelta'} />
                    </div>
                }
                {language === 'en-us' &&
                    <div className='wpo-gallery-padding'>
                        <CleanSectionTitle MainTitle={'Pictures from along the way'} />
                    </div>
                }
                <OurPictures />
                <Footer />
                <Scrollbar />
            </Fragment>
        )
    }
    else {
        return (
            <LoginPage />
        )
    }

};
export default GalleryPage;
