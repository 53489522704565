import React, {Fragment, useTransition} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import StorySection from '../../components/StorySection';
import CoupleSection from '../../components/CoupleSection'
import PortfolioSection2 from '../../components/PortfolioSection2';
import EventSection from '../../components/EventSection';
import Rhobot from '../../components/Rhobot';
import RSVP from '../../components/RSVP';
import Scrollbar from '../../components/scrollbar'
import Footer from '../../components/footer';
import LoginPage from '../LoginPage';
import WeddingPhotos from '../../components/WeddingPhotos';

const HomePage =() => {

    if (localStorage.getItem("userName")!==null) {
    return(
        <Fragment>
            <Navbar hclass={'wpo-site-header-s1'}/>
            <Hero/>
            <EventSection/>
            <CoupleSection/>
            <StorySection/>
            <PortfolioSection2/>
            <Footer/> 
            <Scrollbar/>
        </Fragment>
    )
    }
    else{
        return(
            <LoginPage/>
        )
    }
};

export default HomePage;
